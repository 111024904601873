import React from 'react';
import dataProvider from './dataProvider';
import { Admin, Resource } from 'react-admin';
import { createBrowserHistory as createHistory } from 'history';
import PdfList from './componentes/pdfs/PdfList'
import PdfShow from './componentes/pdfs/PdfShow'
import PdfEdit from './componentes/pdfs/PdfEdit'
import PdfCreate from './componentes/pdfs/PdfCreate'
import LanguageList from './componentes/languages/LanguageList'
import LanguageShow from './componentes/languages/LanguageShow'
import LanguageEdit from './componentes/languages/LanguageEdit'
import LanguageCreate from './componentes/languages/LanguageCreate'
const history = createHistory();

const App = () => {
  return ( 
    <Admin history={history} loginPage={false} dataProvider={dataProvider}>
      <Resource
        name="pdfs"
        create={PdfCreate}
        list={PdfList}
        show={PdfShow}
        edit={PdfEdit}
      />
      <Resource
        name="languages"
        create={LanguageCreate}
        list={LanguageList}
        show={LanguageShow}
        edit={LanguageEdit}
      />
    </Admin>
  );
}
 
export default App;