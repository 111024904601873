import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, BooleanInput } from 'react-admin';

const LanguageCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="prefix" />
        <NumberInput source="position" step={1} />
        <BooleanInput label="active" source="active" />
      </SimpleForm>
    </Create>
  );
};

export default LanguageCreate;