import React from 'react';
import {
  List,
  Datagrid,
  BooleanField,
  TextField,
  EditButton,
  ShowButton,
  DeleteButton,
} from 'react-admin';

const PdfList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="description" />
        <TextField label="Lenguage" source="language.prefix" />
        <BooleanField source="active" />
        <ShowButton label="Show" basePath="/pdfs" />
        <EditButton label="Edit" basePath="/pdfs" />
        <DeleteButton label="Delete" basePath="/pdfs" />
      </Datagrid>
    </List>
  );
};

export default PdfList;