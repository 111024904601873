import React from 'react';
import { Show, TabbedShowLayout, Tab, TextField, ImageField, BooleanField, FileField } from 'react-admin';

const PdfShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label='Informacion basica'>
          <TextField source="id" />
          <TextField source="title" />
          <TextField source="description" />
          <BooleanField source="active" />
          <TextField label="Lenguage" source="language.prefix" />
          <ImageField source="image.src" title="title" />
          <FileField source="file.src" title="title" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default PdfShow;