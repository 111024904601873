import React from 'react';
import { Show, TabbedShowLayout, Tab, TextField, BooleanField } from 'react-admin';

const LanguageShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label='Informacion basica'>
          <TextField disabled source="id" />
          <TextField source="name" />
          <TextField source="prefix" />
          <TextField source="position" />           
          <BooleanField source="active" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default LanguageShow;