import React from 'react';
import { Create, SimpleForm, TextInput, ImageInput, ImageField, 
  FileField, BooleanInput, FileInput, ReferenceInput, SelectInput } 
from 'react-admin';

const EmailCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <TextInput source="description" />
        <BooleanInput source="active" />
        <ReferenceInput label="Language" source="language_id" reference="languages">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ImageInput source="image" label="Imagen" accept="image/*" placeholder={<p>Drop your file here</p>}>
          <ImageField source="src" title="filepath" />
        </ImageInput>
        <FileInput source="file" label="Related files" accept="application/pdf">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default EmailCreate;