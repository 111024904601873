import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = 'https://api.coefficiente.com';
const httpClient = fetchUtils.fetchJson;

function uploadFile(formData, resourceUrl){
  return new Promise((resolve, reject) => {
    return httpClient(`${apiUrl}/${resourceUrl}`, {
      method: 'POST',
      body: formData,
    })
      .then(({ json }) => {
        json.type = resourceUrl
        resolve(json);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      })
  })
}

function vinculateFile(resourceUrl, data){
  return new Promise((resolve, reject) => {
    return httpClient(`${apiUrl}/${resourceUrl}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })
      .then(({ json }) => {
        resolve(json);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      })
  })
}

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      page: page,
      pageSize: perPage,
      sortBy: field,
      sortOrder: order
    };
    if (params.filter.all)
      resource += '/to_auto_complete'

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => {
      if (!json.range)
        return {data: json, total: json.count};

      if (json.length === 0)
      return {
        data: [],
        total: 0
      }
      return {
        data: json[Object.keys(json)[0]].map((resource) => ({ ...resource })),
        total: json.range.total
      }
    });
  },
  getOne: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: { ...json },
    }))
  },
  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      let objectKey = Object.keys(json)[0];
      if (!objectKey)
        return {data: {}};

      return {
        data: json[objectKey].map((resource) => (resource)),
      }
    });
  },
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.map((resource) => ({ ...resource, id: resource._id })),
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },
  update: (resource, params) => {
    var promises = [];
    let formData = new FormData();
    if (params.data.image.rawFile){
      formData.append('file', params.data.image.rawFile);
      promises.push(uploadFile(formData, 'images'));
    }
    if (params.data.file.rawFile){
      formData.append('file', params.data.file.rawFile);
      promises.push(uploadFile(formData, 'files'));
    }
    if (promises.length > 0)
      return Promise.all(promises)
        .then(files => {
          return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
          }).then(({ json }) => {
            promises = [];
            files.forEach(file => {
              let resourceUrl = `${file.type}/${file.id}`;
              let data = {};
              let relationName = resource.replace('s','');
              data[`${relationName}_id`] = json.id;
              promises.push(vinculateFile(resourceUrl, data));
            })
            return Promise.all(promises)
              .then(resolves => {
                return { data: { ...params.data, id: json.id } }
              })
              .catch(err => {
                console.log(err);
              })
          })
        })
        .catch(err => {
          console.log(err);
        })
    else{
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PATCH',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    }

  },
  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  create: (resource, params) => {
    var promises = [];
    let formData = new FormData();
    if (params.data.image){
      formData.append('file', params.data.image.rawFile);
      promises.push(uploadFile(formData, 'images'));
    }
    if (params.data.file){
      formData = new FormData();
      formData.append('file', params.data.file.rawFile);
      promises.push(uploadFile(formData, 'files'));
    }
    if (promises.length > 0)
      return Promise.all(promises)
        .then(files => {
          delete params.data.file;
          delete params.data.image;
          return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
          }).then(({ json }) => {
            promises = [];
            files.forEach(file => {
              let resourceUrl = `${file.type}/${file.id}`;
              let data = {};
              let relationName = resource.replace('s','');
              data[`${relationName}_id`] = json.id;
              promises.push(vinculateFile(resourceUrl, data));
            })
            return Promise.all(promises)
              .then(resolves => {
                return { data: { ...params.data, id: json.id } }
              })
              .catch(err => {
                console.log(err);
              })
          })
        })
        .catch(err => {
          console.log(err);
        })
    else
      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))

  },
  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
      body: JSON.stringify(params.id),
    }).then(({ json }) => { 
      return { data: {id: json.id} }
    }),
  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
}

export default dataProvider;