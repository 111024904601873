import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, BooleanInput } from 'react-admin';

const LanguageEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="prefix" />
        <NumberInput source="position" step={1} />
        <BooleanInput label="active" source="active" />
      </SimpleForm>
    </Edit>
  );
};

export default LanguageEdit;